<template>
  <div class="flex justify-center">
    <q-card
      class="q-pa-md no-shadow"
      :style="{ 'font-family': settings.typography }"
      style="max-width: 500px"
    >
      <div>
        <img class="q-ma-lg img-login" :src="settings.logo" />
      </div>
      <div class="row items-center">
        <q-btn
          dense
          flat
          icon="arrow_back_ios"
          v-close-popup
          class="q-ml-md"
          to="/login"
          style="color: black; font-size: 12px"
        >
          <q-space />
        </q-btn>
      </div>
      <q-form
        class="q-gutter-md"
        autocomplete="off"
        @submit.prevent="resetPassword"
        method="post"
      >
        <q-list>
          <q-item class="q-ma-none q-pa-none">
            <q-item-section>
              <q-item-label class="text-h6">
                {{ $t("Login.new-password") }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-label
                class="q-mb-md text-subtitle2 text-left text-weight-bold q-mr-md q-pa-none"
                >{{ $t("Login.mail") }}
              </q-item-label>
              <q-input
                type="email"
                id="email"
                class="form-control"
                :placeholder="$t('General.mail-example')"
                v-model="email"
                required
                rounded
                outlined
              />
            </q-item-section>
          </q-item>
        </q-list>

        <q-item>
          <q-item-section>
            <q-item-label
              for="email"
              class="q-mb-md text-subtitle2 text-left text-weight-bold q-mr-md q-pa-none"
              >{{ $t("Login.new-password") }}</q-item-label
            >
            <q-input
              rounded
              outlined
              v-model="password"
              :type="isPwd ? 'password' : 'text'"
              :dense="dense"
              :placeholder="$t('Login.your-password')"
              class=""
              autocomplete="on"
            >
              <template v-slot:append>
                <q-icon
                  :name="isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
                />
              </template>
            </q-input>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label
              for="email"
              class="q-mb-md text-subtitle2 text-left text-weight-bold q-mr-md q-pa-none"
              >{{ $t("Login.confirm-new-password") }}</q-item-label
            >
            <q-input
              rounded
              outlined
              v-model="password_confirmation"
              :type="isPwd2 ? 'password' : 'text'"
              :dense="dense"
              :placeholder="$t('Login.new-password')"
              class=""
              autocomplete="on"
            >
              <template v-slot:append>
                <q-icon
                  :name="isPwd2 ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd2 = !isPwd2"
                />
              </template>
            </q-input>
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section class="items-center">
            <q-btn
              class="button-blue"
              :label="$t('Login.apply-for')"
              type="submit"
              :style="{ background: settings.color_primary }"
              style="color: white"
            >
            </q-btn>
          </q-item-section>
        </q-item>
      </q-form>
    </q-card>
  </div>

  <q-dialog persistent v-model="loading" style="max-width: 200px">
    <q-card :style="{ 'font-family': settings.typography }">
      <q-card-section class="text-center">
        <div class="text-body1 q-pb-md">{{ $t("General.wait") }}</div>
        <q-spinner size="40px" />
      </q-card-section>
    </q-card>
  </q-dialog>

  <q-dialog persistent v-model="wrongEmail">
    <q-card :style="{ 'font-family': settings.typography }">
      <q-card-actions align="center">
        <q-btn icon="close" color="red" size="25px" flat />
      </q-card-actions>
      <q-card-section>
        <div class="text-h6 text-center">
          {{ $t("General.problem") }}. {{ $t("Login.problem-password") }}
        </div>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn
          class="btn-home"
          :label="$t('Login.try-again')"
          :style="{ 'background-color': settings.color_accent }"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog persistent v-model="dialogPasswordChanged">
    <q-card :style="{ 'font-family': settings.typography }">
      <q-card-actions align="center">
        <q-btn icon="done" color="green" size="25px" flat />
      </q-card-actions>
      <q-card-section>
        <div class="text-h6 text-center">
          {{ $t("Login.success-password") }}
        </div>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn
          class="btn-home"
          to="/login"
          :label="$t('Login.login')"
          :style="{ 'background-color': settings.color_accent }"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import axios from "../../../../../services/Api";
import settings from "@/mixins/settings";
import { ref } from "vue";

export default {
  mixins: [settings],

  data() {
    return {
      token: null,
      email: null,
      password: null,
      password_confirmation: null,
      loading: false,
      wrongEmail: false,
      dialogPasswordChanged: false,
    };
  },
  setup() {
    return {
      isPwd: ref(true),
      isPwd2: ref(true),
      dense: ref(""),
    };
  },
  methods: {
    async resetPassword() {
      this.loading = true;
      await axios
        .post("front-catalog/reset-password", {
          token: this.$route.query.token,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
        })
        .then(() => {
          this.dialogPasswordChanged = true;
        })
        .catch(() => {
          console.error(error);
          this.wrongEmail = true;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.q-btn {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  margin-top: 30px;
  text-transform: capitalize;
  width: 200px;
  color: white;
}

.q-card {
  border-radius: 25px 25px 25px 0px;
}

.btn-home {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  text-transform: capitalize;
  width: 100%;
  color: white;
  margin: 0;
}
</style>
